import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { useIntl } from "gatsby-plugin-intl";
import Slider from "react-slick";
import ContentWrapper from "../components/ContentWrapper";

const ProjectPage = (props) => {
  const intl = useIntl();
  const project = props.pageContext.project;
  const settings = {
    customPaging: function (i) {
      return (
        <StyledThumb>
          <StyledImg src={project.imageSlider[i]} />
        </StyledThumb>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <Layout>
      <Helmet title={`${project.title}| ${config.siteTitle}`} />
      <ContentWrapper
        type="project"
        headerPicture={project.headerPicture}
        headerTop={project.headerTop}
        summary={project.summary}
        content={project.content}
        bodyPicture={project.bodyPicture}
      />
      {project.imageSlider && project.imageSlider.length > 0 && (
        <SliderLayout>
          <Container>
            <StyledSlider
              {...settings}
              style={{ maxWidth: "750px", maxHeight: "1000px", zIndex: 2 }}
            >
              {project.imageSlider.map((image, index) => {
                return (
                  <StyledItem key={index}>
                    <StyledImg src={image} />
                  </StyledItem>
                );
              })}
            </StyledSlider>
          </Container>
        </SliderLayout>
      )}
    </Layout>
  );
};

const NewsItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: space-between;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const SliderLayout = styled.div`
  max-width: 100vw;
  display: flex;
  border: 0px;
  box-shadow: none;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Container = styled.div`
  width: 750px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  .slick-dots {
    position: relative;
    margin-bottom: 0px;
    margin: 0px;
    li {
      width: 100px;
      height: 75px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 450px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 3px;
`;

export const StyledSlider = styled(Slider)`
  display: flex !important;
  flex-direction: column;
  max-width: 100% !important;
  /* overflow: hidden; */
  max-height: 650px;
  justify-content: center;
  min-height: 650px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    /* overflow: hidden; */
    max-height: 300px;
    justify-content: center;
    min-height: 300px;
    .slick-next {
      display: flex !important;
      right: 25px !important;
      color: #1480c6 !important;
      z-index: 100;
    }
    .slick-prev {
      display: flex !important;
      left: 25px !important;
    }
    .slick-next:before {
      color: #1480c6 !important;
      opacity: 1;
      z-index: 50;
      /* position: absolute; */
      font-size: 40px !important;
      width: 40px !important;
      height: 40px !important;
      right: 25px !important;
    }

    .slick-prev:before {
      color: #1480c6 !important;
      opacity: 1;
      position: absolute;
      z-index: 50;
      font-size: 40px !important;
      width: 20px !important;
      height: 40px !important;
      left: 250px !important;
    }
  }
`;

const StyledItem = styled.div`
  width: 100%;
  display: flex;
  background-color: transparent;
  border-radius: 3px;
  box-shadow: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 550px;
  min-height: 550px;
  height: 550px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    max-height: 300px;
    height: 300px;
    min-height: 300px;
  }
`;

const StyledThumb = styled.a`
  width: 150px;
  height: 75px;

  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 50px;
    height: 25px;
  }
`;

export default ProjectPage;
