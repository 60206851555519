import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { useIntl } from "gatsby-plugin-intl";
import ContentWrapper from "../components/ContentWrapper";
import { SliderLayout, StyledSlider } from "./ProjectPage";

const BlogOverview = (props) => {
  const intl = useIntl();
  const newsItem = props.pageContext.newsItem;
  const settings = {
    customPaging: function (i) {
      return (
        <StyledThumb>
          <StyledImg src={newsItem.imageSlider[i]} />
        </StyledThumb>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Layout>
      <Helmet title={`${newsItem.headerTop} | ${config.siteTitle}`} />
      <ContentWrapper
        type="newsItem"
        headerPicture={newsItem.headerPicture}
        headerTop={newsItem.headerTop}
        summary={newsItem.summary}
        content={newsItem.content}
        bodyPicture={newsItem.bodyPicture}
      />
      {newsItem.imageSlider && newsItem.imageSlider.length > 0 && (
        <SliderLayout>
          <Container>
            {newsItem.imageSlider.length > 1 ? 
            <StyledSlider
              {...settings}
              style={{ maxWidth: "600px", maxHeight: "1000px", zIndex: 2 }}
            >
              {newsItem.imageSlider.map((image, index) => {
                return (
                  <StyledItem key={index}>
                    <StyledImg src={image} />
                  </StyledItem>
                );
              })}
    
            </StyledSlider>
            :
            newsItem.imageSlider && newsItem.imageSlider[0] &&
            <StyledItem>
                    <StyledImg src={newsItem.imageSlider[0]} />
                  </StyledItem>
}
          </Container>
        </SliderLayout>
      )}
    </Layout>
  );
};

const NewsItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: space-between;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const Container = styled.div`
  width: 750px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  .slick-dots {
    position: relative;
    margin-bottom: 0px;
    margin: 0px;
    li {
      width: 100px;
      height: 75px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1080px) {
    width: 450px;
  }
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  border-radius: 3px;
`;

const StyledItem = styled.div`
  width: 600px;
  height: 400px;
`;

const StyledThumb = styled.a`
  width: 150px;
  height: 75px;
`;

export default BlogOverview;
